<template>
  <div>
    <h2 class="text-primary mt-60 mb-30">
      Social and environmental assessments
    </h2>
    <div class="bg-white py-40 mb-25 mt-55">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group mb-0">
            <div class="form-row">
              <div class="col-md-12 col-lg-4">
                <label class="col-form-label">Select Year</label>
              </div>
              <div class="col-md-12 col-lg-8">
                <select v-model="year" class="form-control admin-input">
                  <option value="">Select year</option>
                  <option v-for="(year, _key) in years" :key="_key" :value="year">{{ year }}</option>
                </select>
                <div
                  v-if="$v.$error & !$v.year.required"
                  class="error">
                  Year is required.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-primary-light en-dr-bg1 p-20">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="row no-gutters p-lg-0 p-20">
            <div class="col-md-8 ml-auto">
              <div class="d-flex justify-content-between align-items-center">
                <p class="w-50 m-0"> More than one assessment can be added by clicking on this button </p>
                <button class="btn btn-add" type="submit" @click="addMoreAssessmentWidget"> + Add Titles</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div v-for="(_assessment, _index) in assessmentsWidget" :key="_index" class="form-group mb-55">
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Assessments Period</label>
                </div>
                <div class="col-md-12 col-lg-6">
                  <input v-model="_assessment.title" class="admin-input form-control"
                         placeholder="Type Assessments Period"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.assessmentsWidget.$each.$iter[_index].title.required"
                    class="error">
                    Assessments period is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="d-inline-flex float-right">
                    <button
                      v-if="assessmentsWidget.length > 1"
                      class="btn btn-add-success float-right"
                      @click="removeFromChildForm(assessmentsWidget,_index, 'api', cardContentsState)">
                      <i aria-hidden="true" class="fa fa-times"></i> Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Identified priority area</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <input v-model="_assessment.subtitle" class="admin-input form-control" placeholder="Type Sample size"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.assessmentsWidget.$each.$iter[_index].subtitle.required"
                    class="error">
                    Priority area is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Sample size</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <input v-model="_assessment.description" class="admin-input form-control"
                         placeholder="Type Sample size"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.assessmentsWidget.$each.$iter[_index].description.required"
                    class="error">
                    Sample size is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Order</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <input v-model="_assessment.order" class="admin-input form-control"
                         placeholder="Type order"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.assessmentsWidget.$each.$iter[_index].order.required"
                    class="error">
                    Order is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.assessmentsWidget.$each.$iter[_index].order.numeric"
                    class="error">
                    Only positive number is acceptable.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveAssessmentWidget">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <EachCountryAssessmentCard :country="country" :year="year"></EachCountryAssessmentCard>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import { numeric, required } from 'vuelidate/lib/validators'
import UtilityMixin from '@/mixins/utility-mixin'
import EachCountryAssessmentCard
  from '@/components/admin/inclusive-sustainability/each-country/EachCountryAssessmentCard'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'

export default {
  name: 'EachCountryAssessmentWidget',
  components: { EachCountryAssessmentCard },
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      year: '',
      assessmentsWidget: [
        {
          description: '',
          subtitle: '',
          title: '',
          order: 0
        }
      ]
    }
  },
  props: {
    country: {
      type: Object,
      required: true,
      default: function () {
        return {
          code: '',
          name: ''
        }
      }
    }
  },
  validations: {
    year: { required },
    assessmentsWidget: {
      required,
      $each: {
        title: {
          required
        },
        subtitle: {
          required
        },
        description: {
          required
        },
        order: {
          required,
          numeric
        }
      }
    }
  },
  computed: {
    cardContentsState () {
      return this.$store.state.InclusiveSustainability.sustainabilityContents
    },
    widgetDecksByYear () {
      const _year = this.year === 'all' ? this.year : JSON.stringify(this.year)
      return this.$store.getters.getSustainabilityCountryYearWidgetDecks(
        this.country.name, _year)
    }
  },
  methods: {
    addMoreAssessmentWidget () {
      this.assessmentsWidget.push({
        description: '',
        subtitle: '',
        title: '',
        order: 1
      })
    },
    async saveAssessmentWidget () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const _promises = []
          this.assessmentsWidget.map((item) => {
            const response = this.submitEachData(item)
            _promises.push(response)
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(_promises).then((values) => {
            this.$notify({
              title: `${this.country.name}: Assessment Widget`,
              text: 'Assessment Widget updated successfully.',
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachData (data) {
      const formData = this.$_.cloneDeep(data)
      formData.content = this.$store.getters.getContentByAppModule(Menus.inclusiveSustainability.key).id
      formData.content_type = ContentTypes.KEY_VALUE
      formData.country = this.country.id
      formData.year = this.year
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.WIDGET_DECK, Menus.inclusiveSustainability.key).id
      return await this.$store.dispatch('createOrUpdateSustainabilityContentItem', formData)
    }
  },
  watch: {
    year (newValue) {
      this.$emit('onYearChange', newValue)
    },
    widgetDecksByYear: {
      deep: true,
      handler (newValue) {
        if (newValue.length !== 0) {
          this.assessmentsWidget = _.cloneDeep(newValue)
        } else {
          this.assessmentsWidget = [{
            description: '',
            subtitle: '',
            title: '',
            order: 1
          }]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}
</style>
