<template>
  <div>
    <h3 class="mt-50 mb-30">NKG Bloom Criteria</h3>
    <div class="bg-primary-light en-dr-bg1 p-20">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="row no-gutters p-lg-0 p-20">
            <div class="col-md-8 ml-auto">
              <div class="d-flex justify-content-between align-items-center">
                <p class="w-50 m-0"> More than one item can be added by clicking on this button </p>
                <button class="btn btn-add" type="submit" @click="addMoreCriterias"> + Add Titles</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div v-for="(_kStats, _index) in criterias" :key="_index" class="mb-30 mt-35">
            <div class="form-group">
              <div class="form-group mb-30">
                <div class="form-row">
                  <div class="col-md-12 col-lg-4">
                    <label class="col-form-label">Order</label>
                  </div>
                  <div class="col-md-12 col-lg-8">
                    <input v-model="_kStats.order" class="admin-input form-control"
                           placeholder="Type order"
                           type="text"/>
                    <div
                      v-if="$v.$error & !$v.criterias.$each.$iter[_index].order.required"
                      class="error">
                      Order is required.
                    </div>
                    <div
                      v-if="$v.$error & !$v.criterias.$each.$iter[_index].order.numeric"
                      class="error">
                      Only positive number is acceptable.
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-30">
                <div class="form-row">
                  <div class="col-md-12 col-lg-4">
                    <label class="col-form-label">Criteria Name</label>
                  </div>
                  <div class="col-md-12 col-lg-6">
                    <input v-model="_kStats.title" class="form-control admin-input"
                           placeholder="Type criteria"
                           type="text">
                    <div
                      v-if="$v.$error & !$v.criterias.$each.$iter[_index].title.required"
                      class="error">
                      Criteria name is required.
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="d-inline-flex float-right">
                      <button
                        v-if="criterias.length > 1"
                        class="btn btn-add-success float-right"
                        @click="removeFromChildForm(criterias,_index, 'api', cardContentsState)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group mb-30">
                <div class="form-row">
                  <div class="col-md-12 col-lg-4">
                    <label class="col-form-label">Criteria Description</label>
                  </div>
                  <div class="col-md-12 col-lg-8">
                 <textarea v-model="_kStats.description" class="admin-input form-control"
                           placeholder="Type criteria description" rows="5"
                           type="text"></textarea>
                    <div
                      v-if="$v.$error & !$v.criterias.$each.$iter[_index].description.required"
                      class="error">
                      Criteria description is required.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveCriterias">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import UtilityMixin from '@/mixins/utility-mixin'
import { numeric, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'

export default {
  name: 'AllCountryCriteria',
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      criterias: [
        {
          title: '',
          description: '',
          order: 0
        }
      ]
    }
  },
  validations: {
    criterias: {
      required,
      $each: {
        title: {
          required
        },
        order: {
          required,
          numeric
        },
        description: {
          required
        }
      }
    }
  },
  computed: {
    cardContentsState () {
      return this.$store.state.InclusiveSustainability.sustainabilityContents
    },
    bloomCriteria () {
      return this.$store.getters.getSustainabilityAllCountryCriteriums
    }
  },
  methods: {
    addMoreCriterias () {
      this.criterias.push({
        title: '',
        description: '',
        order: 1
      })
    },
    async saveCriterias () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const _promises = []
          this.criterias.map((_data) => {
            const response = this.submitEachCriteria(_data)
            _promises.push(response)
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(_promises).then((values) => {
            this.$notify({
              title: 'Criteria',
              text: 'Criteria updated successfully.',
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachCriteria (data) {
      const formData = this.$_.cloneDeep(data)
      formData.content = this.$store.getters.getContentByAppModule(
        Menus.inclusiveSustainability.key).id
      formData.content_type = ContentTypes.CARD
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.CRITERIA, Menus.inclusiveSustainability.key).id
      return await this.$store.dispatch('createOrUpdateSustainabilityContentItem', formData)
    }
  },
  watch: {
    bloomCriteria: {
      deep: true,
      handler (newValue) {
        this.criterias = _.cloneDeep(newValue)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}

textarea {
  white-space: pre-wrap;

  .form-control {
    padding-top: 15px;
  }
}

.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
