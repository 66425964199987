<template>
  <div class="bg-white py-40 mb-30">
    <div class="row no-gutters p-lg-0 p-20">
      <div class="col-lg-7 col-md-12 m-auto">
        <div class="form-group">
          <div class="form-row">
            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
              <label class="col-form-label">Title</label>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-sm-12">
              <input
                v-model="greetings.title" class="admin-input form-control"
                placeholder="Your title" type="text">
              <div
                v-if="$v.$error & !$v.greetings.title.required"
                class="error">
                Title is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <div class="form-row">
            <div class="col-12 col-md-12 col-lg-4 col-sm-12">
              <label class="col-form-label">Description</label>
            </div>
            <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <textarea v-model="greetings.description" class="admin-input form-control"
                          placeholder="Type full description" rows="5"
                          type="text"></textarea>
              <div
                v-if="$v.$error & !$v.greetings.description.required"
                class="error">
                Title is required.
              </div>
            </div>
          </div>
        </div>
        <div class="form-group mt-35">
          <div class="form-row">
            <div class="col-md-12 col-lg-4">
              <label class="col-form-label">Group
                logo</label>
            </div>
            <div class="col-md-12 col-lg-8">
              <div class="drop drop-inline mr-30 drop-sm">
                <div class="cont">
                  <img
                    v-if="greetings.icon && greetings.icon.previewUrl"
                    :src="greetings.icon.previewUrl" alt="Icon" class="icon-preview"/>
                  <img
                    v-else-if="greetings.icon && photoPreviewMapping[greetings.icon.tsync_id]"
                    :src="photoPreviewMapping[greetings.icon.tsync_id]" alt="Icon" class="icon-preview"/>
                  <img
                    v-else-if="greetings.icon && greetings.icon.relative_url"
                    :src="greetings.icon.relative_url | makeAbsUrl" alt="Icon"
                    class="icon-preview"/>
                  <img
                    v-else alt="Icon" src="@/assets/img/icons/upload1.svg">
                  <div v-if="!greetings.icon || !greetings.icon.name" class="desc"> Upload Icon</div>
                  <div v-if="greetings.icon && greetings.icon.name" class="desc">
                    {{ greetings.icon.name | textTruncate(15) }}
                  </div>
                </div>
                <input ref="imageFileUploader" accept="image/*" type="file" @change="onIconChange($event)"
                       @click="resetImageFileUploader">
                <div
                  v-if="$v.$error & !$v.greetings.icon.tsync_id.required" class="error">
                  Icon is required.
                </div>
              </div>
              <div v-if="greetings.icon && greetings.icon.name" class="d-inline-flex">
                <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                   @click="clearPicture(greetings)"> Clear</i>
              </div>
            </div>
          </div>
        </div>
        <div class="form-row ">
          <div class="col-md-12 m-auto">
            <div class="text-right mt-30">
              <div class="d-inline-flex">
                <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                        @click="saveGreetingsData">
                  {{ saveButtonTxt }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import UtilityMixin from '@/mixins/utility-mixin'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { ContentTypes } from '@/config/constants'
import Menus from '@/config/menus'

export default {
  name: 'EachCountryGreetings',
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      greetings: {
        description: '',
        title: '',
        icon: {
          file: null,
          name: null,
          previewUrl: null,
          tsync_id: null
        }
      },
      photoPreviewMapping: {},
      photoTsyncIdMapping: {}
    }
  },
  props: {
    country: {
      type: Object,
      required: true,
      default: function () {
        return {
          public_code: '',
          name: ''
        }
      }
    }
  },
  validations: {
    required,
    greetings: {
      title: {
        required
      },
      description: {
        required
      },
      icon: {
        tsync_id: {
          required
        }
      }
    }
  },
  computed: {
    greetingsContent () {
      return this.$store.getters.getSustainabilityCountryGreetings(this.country.name)
    }
  },
  methods: {
    onIconChange (e) {
      // index => key statistics array objects index
      // if (!this.greetings.icon) {
      //   this.greetings.icon = {}
      // }
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.greetings.icon.previewUrl = URL.createObjectURL(file)
        this.greetings.icon.file = file
        this.greetings.icon.name = file.name
        this.greetings.icon.tsync_id = this.uuidV4()
      } else {
        // if icon upload widget clicked and no image selected
        this.greetings.icon.previewUrl = null
        this.greetings.icon.file = null
        this.greetings.icon.name = null
        this.greetings.icon.tsync_id = null
      }
      this.$forceUpdate()
    },
    clearPicture (object) {
      object.icon.previewUrl = null
      object.icon.file = null
      object.icon.name = null
      object.icon.tsync_id = null
      object.icon.relative_url = null
      this.$forceUpdate()
    },
    async saveGreetingsData () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const vm = this
          this.photoTsyncIdMapping = {}
          this.photoPreviewMapping = {}

          const _contentItemPromises = []
          const response = this.submitEachData(this.greetings)
          _contentItemPromises.push(response)
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(_contentItemPromises).then((values) => {
            const photoPromises = []
            values.map((item) => {
              const iconId = item.data.icon ? item.data.icon.id : 0
              const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
              if (iconId === 0 || iconTsyncId === '') return
              const photoFile = vm.photoTsyncIdMapping[iconTsyncId]
              if (_.get(photoFile, 'name')) {
                const response = vm.uploadMultipartPhoto(iconId, photoFile)
                photoPromises.push(response)
              }
            })
            Promise.all(photoPromises).then((iconResponses) => {
              this.$notify({
                title: `${this.country.name}: Inclusive Sustainability`,
                text: 'Title and description updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              this.$forceUpdate()
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachData (data) {
      const vm = this
      const formData = _.cloneDeep(data)
      const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : vm.uuidV4()
      if (_.get(data, 'icon.file')) {
        vm.photoTsyncIdMapping[iconTsyncId] = data.icon.file
      }
      if (data.icon && data.icon.previewUrl) {
        vm.photoPreviewMapping[iconTsyncId] = data.icon.previewUrl
      }
      if (!formData.icon.id && formData.icon.name) {
        formData.icon = {
          tsync_id: iconTsyncId,
          extension: formData.icon.name.split('.').pop()
        }
      } else {
        try {
          formData.icon.extension = formData.icon.name.split('.').pop()
        } catch (e) {

        }
      }
      formData.content = this.$store.getters.getContentByAppModule(Menus.inclusiveSustainability.key).id
      formData.content_type = ContentTypes.GREETING
      formData.country = this.country.id
      return await this.$store.dispatch('createOrUpdateSustainabilityContentItem', formData)
    }
  },
  watch: {
    greetingsContent: {
      deep: true,
      handler (newValue) {
        if (!this.$_.isEmpty(newValue)) {
          this.greetings = _.cloneDeep(newValue)
        } else {
          this.greetings = {
            description: '',
            title: '',
            icon: {
              file: null,
              name: null,
              previewUrl: null,
              tsync_id: null
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}

.icon-preview {
  height: 42px;
}

.clear-file {
  cursor: pointer;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
