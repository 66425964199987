<template>
  <section class="my-60">
    <bw-loader v-if="loading"/>
    <div class="container" v-show="!loading">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col">
          <div>
            <ul class="nav nav-tabs text-center justify-content-center tab-with-dot">
              <li class="nav-item">
                <a class="nav-link active text-dark" data-toggle="tab" href="#tab-1" role="tab">
                  <div class="dot">
                    <div class="sm-dot"></div>
                  </div>
                  All Countries </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#tab-2" role="tab">
                  <div class="dot">
                    <div class="sm-dot"></div>
                  </div>
                  Individual Country </a>
              </li>
            </ul>
            <div class="tab-content mt-40">
              <div id="tab-1" class="tab-pane fade show active" role="tabpanel">
                <all-country></all-country>
              </div>
              <div id="tab-2" class="tab-pane fade" role="tabpanel">
                <each-country></each-country>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AllCountry from '@/components/admin/inclusive-sustainability/AllCountry'
import EachCountry from '@/components/admin/inclusive-sustainability/EachCountry'
import BwLoader from '@/components/ui/BwLoader'

export default {
  name: 'InclusiveSustainability',
  components: {
    BwLoader,
    EachCountry,
    AllCountry
  },
  data () {
    return {
      loading: false
    }
  },
  async created () {
    this.loading = true
    await this.$store.dispatch('getSustainabilityContentsFromServer')
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
.nav-tabs .nav-link.active {
  color: #006330;
  border-color: #006330;
}

.tab-with-dot .nav-link.active {
  color: #69aa1a !important;
  border: none;
  font-size: 1.125rem;
}

.nav-tabs .nav-link.active {
  border: none !important;
  background-color: transparent;
  border-color: #dee2e6 #dee2e6 #fff;
  font-family: "Lucida";
  font-size: 1rem;
  line-height: 1.375rem;
  letter-spacing: -0.19px;
  color: #69aa1a;
  border-bottom: 3px solid #69aa1a;
}
</style>
