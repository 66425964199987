<template>
  <InclusiveSustainability></InclusiveSustainability>
</template>

<script>
import InclusiveSustainability from '@/components/admin/inclusive-sustainability/InclusiveSustainability'

export default {
  name: 'InclusiveSustainabilityView',
  components: {
    InclusiveSustainability
  }
}
</script>

<style scoped>

</style>
