<template>
  <div>
    <div class="d-flex justify-content-between mt-65 mb-45">
      <div>
        <h2 class="text-primary"></h2>
        <p></p>
      </div>
      <div>
        <button class="btn btn-add" type="submit" @click="addMoreCardDecks"> + Add</button>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div v-for="(_kStats, _index) in cardDecks" :key="_index" class="form-group mb-30">
            <div class="form-row">
              <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                <label class="col-form-label"></label>
              </div>
              <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <div class="d-block">
                  <div class="form-group">
                    <div class="drop drop-inline mr-30 drop-sm">
                      <div class="cont">
                        <img
                          v-if="_kStats.icon && _kStats.icon.previewUrl"
                          :src="_kStats.icon && _kStats.icon.previewUrl" alt="Icon" class="icon-preview"/>
                        <img
                          v-else-if="_kStats.icon && photoPreviewMapping[_kStats.icon.tsync_id]"
                          :src="photoPreviewMapping[_kStats.icon.tsync_id]" alt="Icon" class="icon-preview"/>
                        <img
                          v-else-if="_kStats.icon && !_kStats.icon.previewUrl && !_kStats.icon.relative_url"
                          alt="" src="@/assets/img/icons/upload1.svg">
                        <img
                          v-else :src="_kStats.icon && _kStats.icon.relative_url | makeAbsUrl" alt="Icon"
                          class="icon-preview"/>
                        <div v-if="_kStats.icon && !_kStats.icon.name" class="desc"> Upload Icon</div>
                        <div v-if="_kStats.icon && _kStats.icon.name" class="desc">
                          {{ _kStats.icon.name | textTruncate(15) }}
                        </div>
                      </div>
                      <input type="file" @change="onIconChange($event,_index)">
                      <div
                        v-if="$v.$error & !$v.cardDecks.$each.$iter[_index].icon.tsync_id.required"
                        class="error">
                        Icon is required.
                      </div>
                    </div>
                    <div class="d-inline-flex float-right">
                      <button
                        v-if="cardDecks.length > 1"
                        class="btn btn-add-success float-right"
                        @click="removeFromChildForm(cardDecks,_index,'api', cardContentsState)">
                        <i aria-hidden="true" class="fa fa-times"></i> Close
                      </button>
                    </div>
                    <div v-if="_kStats.icon" class="d-inline-flex">
                      <i aria-hidden="true" class="fa fa-times ml-2 clear-image"
                         @click="clearIcon(cardDecks, _index)"> Clear</i>
                    </div>
                  </div>
                  <div class="form-group">
                    <textarea v-model="_kStats.description" class="admin-input form-control"
                              placeholder="Type brief description" rows="5"
                              type="text"></textarea>
                  </div>
                  <div
                    v-if="$v.$error & !$v.cardDecks.$each.$iter[_index].description.required"
                    class="error">
                    Description is required.
                  </div>
                  <div class="form-group">
                    <input v-model="_kStats.order" class="admin-input form-control"
                           placeholder="Type order"
                           type="text"/>
                  </div>
                  <div
                    v-if="$v.$error & !$v.cardDecks.$each.$iter[_index].order.required"
                    class="error">
                    Order is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.cardDecks.$each.$iter[_index].order.numeric"
                    class="error">
                    Only positive number is acceptable.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveCardDecks">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import UtilityMixin from '@/mixins/utility-mixin'
import { numeric, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'

export default {
  name: 'AllCountryCard',
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      cardDecks: [
        {
          icon: {
            previewUrl: null,
            file: null,
            name: null,
            tsync_id: null
          },
          description: '',
          order: 0
        }
      ],
      photoTsyncIdMapping: {},
      photoPreviewMapping: {}
    }
  },
  props: {},
  validations: {
    required,
    cardDecks: {
      required,
      $each: {
        description: {
          required
        },
        order: {
          required,
          numeric
        },
        icon: {
          tsync_id: {
            required
          }
        }
      }
    }
  },
  computed: {
    cardContentsState () {
      return this.$store.state.InclusiveSustainability.sustainabilityContents
    },
    cardDeckContents () {
      return this.$store.getters.getSustainabilityAllCountryCardDecks
    }
  },
  methods: {
    addMoreCardDecks () {
      this.cardDecks.push({
        icon: {
          previewUrl: null,
          file: null,
          name: null,
          tsync_id: null
        },
        description: '',
        order: 1
      })
    },
    onIconChange (e, index) {
      // index => key statistics array objects index
      if (e.target.files.length > 0) {
        const file = e.target.files[0]
        this.cardDecks[index].icon.previewUrl = URL.createObjectURL(file)
        this.cardDecks[index].icon.file = file
        this.cardDecks[index].icon.name = file.name
        this.cardDecks[index].icon.tsync_id = this.uuidV4()
      } else {
        // if icon upload widget clicked and no image selected
        this.cardDecks[index].icon.previewUrl = null
        this.cardDecks[index].icon.file = null
        this.cardDecks[index].icon.name = null
        this.cardDecks[index].icon.tsync_id = null
      }
    },
    async saveCardDecks () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const vm = this
          this.photoTsyncIdMapping = {}
          this.photoPreviewMapping = {}
          this.fileTsyncIdMapping = {}

          const _contentItemPromises = []
          const promises = await vm.cardDecks.reduce(async (memo, v) => {
            await memo
            _contentItemPromises.push(await vm.submitEachData(v))
          }, [])
          _.unset(promises, '')
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(_contentItemPromises).then((values) => {
            const photoPromises = []
            values.map((item) => {
              const iconId = item.data.icon ? item.data.icon.id : 0
              const iconTsyncId = item.data.icon ? item.data.icon.tsync_id : ''
              if (iconId === 0 || iconTsyncId === '') return
              const photoFile = vm.photoTsyncIdMapping[iconTsyncId]
              if (_.get(photoFile, 'name')) {
                const response = vm.uploadMultipartPhoto(iconId, photoFile)
                photoPromises.push(response)
              }
            })
            Promise.all(photoPromises).then((iconResponses) => {
              this.$notify({
                title: 'Card Deck',
                text: 'Card deck updated successfully.',
                type: 'success', // 'warn', 'error', 'success'
                duration: 5000
              })
              // this.$forceUpdate()
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachData (data) {
      const vm = this
      const formData = _.cloneDeep(data)
      const iconTsyncId = (formData.icon && formData.icon.tsync_id) ? formData.icon.tsync_id : vm.uuidV4()
      if (_.get(data, 'icon.file')) {
        vm.photoTsyncIdMapping[iconTsyncId] = data.icon.file
      }
      if (data.icon && data.icon.previewUrl) {
        vm.photoPreviewMapping[iconTsyncId] = data.icon.previewUrl
      }
      if (!formData.icon.id && formData.icon.name) {
        formData.icon = {
          tsync_id: iconTsyncId,
          extension: formData.icon.name.split('.').pop()
        }
      } else {
        try {
          formData.icon.extension = formData.icon.name.split('.').pop()
        } catch (e) {

        }
      }

      formData.content = this.$store.getters.getContentByAppModule(Menus.inclusiveSustainability.key).id
      formData.content_type = ContentTypes.CARD
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.CARD_DECK, Menus.inclusiveSustainability.key).id
      return await this.$store.dispatch('createOrUpdateSustainabilityContentItem', formData)
    }
  },
  watch: {
    cardDeckContents: {
      deep: true,
      handler (newValue) {
        if (newValue.length > 0) {
          this.cardDecks = _.cloneDeep(newValue)
        } else {
          this.cardDecks = [{
            icon: {
              previewUrl: null,
              file: null,
              name: null,
              tsync_id: null
            },
            description: '',
            order: 1
          }]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}

.icon-preview {
  height: 42px;
}

.clear-image {
  cursor: pointer;
}

.drop-sm {
  padding-right: 60px !important;
  max-width: 52%; // if file name is long than it wont overflow next close button
}
</style>
