<template>
  <div>
    <div class="bg-primary-light en-dr-bg1 p-20">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="row no-gutters p-lg-0 p-20">
            <div class="col-md-8 ml-auto">
              <div class="d-flex justify-content-between align-items-center">
                <p class="w-50 m-0"> More than one item can be added by clicking on this button </p>
                <button class="btn btn-add" type="submit" @click="addMoreAssessmentCard"> + Add Titles</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white py-40">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div v-for="(_assessment, _index) in assessmentsCards" :key="_index" class="form-group mb-55">
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Identified priority area</label>
                </div>
                <div class="col-md-12 col-lg-6">
                  <input v-model="_assessment.title" class="admin-input form-control"
                         placeholder="Type Assessments title"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.assessmentsCards.$each.$iter[_index].title.required"
                    class="error">
                    Title is required.
                  </div>
                </div>
                <div class="col-lg-2">
                  <div class="d-inline-flex float-right">
                    <button
                      v-if="assessmentsCards.length > 1"
                      class="btn btn-add-success float-right"
                      @click="removeFromChildForm(assessmentsCards,_index, 'api', cardContentsState)">
                      <i aria-hidden="true" class="fa fa-times"></i> Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Identified priority area description</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <textarea v-model="_assessment.description" class="admin-input form-control"
                            placeholder="Type Assessments Description" rows="5"
                            type="text"></textarea>
                  <div
                    v-if="$v.$error & !$v.assessmentsCards.$each.$iter[_index].description.required"
                    class="error">
                    Assessments description is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group mb-30">
              <div class="form-row">
                <div class="col-md-12 col-lg-4">
                  <label class="col-form-label">Order</label>
                </div>
                <div class="col-md-12 col-lg-8">
                  <input v-model="_assessment.order" class="admin-input form-control"
                         placeholder="Type order"
                         type="text">
                  <div
                    v-if="$v.$error & !$v.assessmentsCards.$each.$iter[_index].order.required"
                    class="error">
                    Order is required.
                  </div>
                  <div
                    v-if="$v.$error & !$v.assessmentsCards.$each.$iter[_index].order.numeric"
                    class="error">
                    Only positive number is acceptable.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group mb-30">
            <div class="row">
              <div class="col-12 col-md-12 col-lg-12 col-sm-12">
                <div class="text-right mt-30">
                  <div class="d-inline-flex">
                    <button v-if="!isObserverUser" :disabled="!isSaveButtonActive" class="btn btn-submit" type="button"
                            @click="saveAssessmentCard">
                      {{ saveButtonTxt }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormMixin from '@/mixins/form-mixin'
import { validationMixin } from 'vuelidate'
import { numeric, required } from 'vuelidate/lib/validators'
import UtilityMixin from '@/mixins/utility-mixin'
import Menus from '@/config/menus'
import { ContentTypes } from '@/config/constants'

export default {
  name: 'EachCountryAssessmentCard',
  mixins: [FormMixin, validationMixin, UtilityMixin],
  data () {
    return {
      assessmentsCards: [
        {
          description: '',
          title: '',
          order: 0
        }
      ]
    }
  },
  props: {
    country: {
      type: Object,
      required: true,
      default: function () {
        return {
          public_code: '',
          name: ''
        }
      }
    },
    year: {
      type: [String, Number],
      required: true
    }
  },
  validations: {
    assessmentsCards: {
      required,
      $each: {
        title: {
          required
        },
        order: {
          required,
          numeric
        },
        description: {
          required
        }
      }
    }
  },
  computed: {
    cardContentsState () {
      return this.$store.state.InclusiveSustainability.sustainabilityContents
    },
    cardDecksByYear () {
      const _year = this.year === 'all' ? this.year : JSON.stringify(this.year)
      return this.$store.getters.getSustainabilityCountryCardDecksByYear(
        this.country.name, _year)
    }
  },
  methods: {
    addMoreAssessmentCard () {
      this.assessmentsCards.push({
        description: '',
        title: '',
        order: 1
      })
    },
    async saveAssessmentCard () {
      try {
        this.isSaveButtonActive = false
        this.saveButtonTxt = 'Submitting...'

        this.$v.$touch()
        if (this.$v.$invalid) {
          this.$notify({
            title: '',
            text: 'Please fix error(s) in form.',
            type: 'error',
            duration: 5000
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
        } else {
          const _promises = []
          this.assessmentsCards.map((item) => {
            const response = this.submitEachData(item)
            _promises.push(response)
          })
          this.isSaveButtonActive = true
          this.saveButtonTxt = 'Submit'
          Promise.all(_promises).then((values) => {
            this.$notify({
              title: `${this.country.name}: Assessment Card`,
              text: 'Assessment Card updated successfully.',
              type: 'success', // 'warn', 'error', 'success'
              duration: 5000
            })
          })
        }
      } catch (e) {
        this.isSaveButtonActive = true
        this.saveButtonTxt = 'Submit'
        this.errorLog(e)
      }
    },
    async submitEachData (data) {
      const formData = this.$_.cloneDeep(data)
      formData.content = this.$store.getters.getContentByAppModule(Menus.inclusiveSustainability.key).id
      formData.content_type = ContentTypes.MINI_CARD
      formData.country = this.country.id
      formData.year = this.year
      formData.parent = this.$store.getters.getParentContentItemByContentType(
        ContentTypes.CARD_DECK, Menus.inclusiveSustainability.key).id
      return await this.$store.dispatch('createOrUpdateSustainabilityContentItem', formData)
    }
  },
  watch: {
    cardDecksByYear: {
      deep: true,
      handler (newValue) {
        if (newValue.length !== 0) {
          this.assessmentsCards = _.cloneDeep(newValue)
        } else {
          this.assessmentsCards = [{
            description: '',
            title: '',
            order: 1
          }]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
textarea.form-control {
  padding-top: 15px;
}
</style>
