<template>
  <div>
    <div class="bg-white py-40 mb-25">
      <div class="row no-gutters p-lg-0 p-20">
        <div class="col-lg-7 col-md-12 m-auto">
          <div class="form-group">
            <div class="form-row">
              <div class="col-12 col-md-12 col-lg-4 col-sm-12">
                <label class="col-form-label">Select Country</label>
              </div>
              <div class="col-12 col-md-12 col-lg-8 col-sm-12">
                <select v-model="country" class="form-control admin-input">
                  <option :value="{}">Select Countries</option>
                  <option v-for="(country, _index) in countries" :key="_index"
                          :value="{...country}">
                    {{ country.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EachCountryGreetings v-show="country.name" :country="country"/>
    <EachCountryAssessmentWidget v-show="country.name" :country="country" @onYearChange="yearUpdated"/>
    <EachCountryFurtherResource v-show="country.name" :country="country"/>
  </div>
</template>

<script>
import EachCountryAssessmentWidget
  from '@/components/admin/inclusive-sustainability/each-country/EachCountryAssessmentWidget'
import EachCountryFurtherResource
  from '@/components/admin/inclusive-sustainability/each-country/EachCountryFurtherResource'
import EachCountryGreetings from '@/components/admin/inclusive-sustainability/each-country/EachCountryGreetings'
import _ from 'lodash'

export default {
  name: 'EachCountry',
  components: {
    EachCountryGreetings,
    EachCountryAssessmentWidget,
    EachCountryFurtherResource
  },
  data () {
    return {
      country: {
        name: '',
        public_code: ''
      },
      year: 'all'
    }
  },
  computed: {
    countries () {
      return _.sortBy(this.$store.getters.getNkgCountry, 'name')
    }
  },
  methods: {
    yearUpdated (year) {
      this.year = year
    }
  }
}
</script>
