<template>
  <div>
    <AllCountryGreetings></AllCountryGreetings>
    <AllCountryCard></AllCountryCard>
    <AllCountryCriteria></AllCountryCriteria>
  </div>
</template>

<script>
import AllCountryCriteria from '@/components/admin/inclusive-sustainability/all-country/AllCountryCriteria'
import AllCountryGreetings from '@/components/admin/inclusive-sustainability/all-country/AllCountryGreetings'
import AllCountryCard from '@/components/admin/inclusive-sustainability/all-country/AllCountryCard'

export default {
  name: 'AllCountry',
  components: {
    AllCountryCriteria,
    AllCountryGreetings,
    AllCountryCard
  }
}
</script>

<style scoped>

</style>
